import * as React from "react";

export const AppleIcon = ({ className }: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={`${className} block`}
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_34_1274)">
      <path
        d="M18.9333 8.1816C18.794 8.2896 16.3339 9.6744 16.3339 12.7536C16.3339 16.3152 19.4643 17.5752 19.558 17.6064C19.5435 17.6832 19.0607 19.332 17.9075 21.012C16.8793 22.4904 15.8054 23.9664 14.1718 23.9664C12.5381 23.9664 12.1177 23.0184 10.2318 23.0184C8.39399 23.0184 7.74054 23.9976 6.24625 23.9976C4.75195 23.9976 3.70931 22.6296 2.51051 20.9496C1.12192 18.9768 0 15.912 0 13.0032C0 8.3376 3.03664 5.8632 6.02522 5.8632C7.61321 5.8632 8.93694 6.9048 9.93393 6.9048C10.8829 6.9048 12.3628 5.8008 14.1694 5.8008C14.8541 5.8008 17.3141 5.8632 18.9333 8.1816ZM13.3117 3.8256C14.0589 2.94 14.5874 1.7112 14.5874 0.4824C14.5874 0.312 14.573 0.1392 14.5417 0C13.3261 0.0456 11.8799 0.8088 11.0078 1.8192C10.3231 2.5968 9.68408 3.8256 9.68408 5.0712C9.68408 5.2584 9.71531 5.4456 9.72973 5.5056C9.80661 5.52 9.93153 5.5368 10.0565 5.5368C11.1471 5.5368 12.5189 4.8072 13.3117 3.8256Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_34_1274">
        <rect width="19.5556" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
