import { browserPopupRedirectResolver, GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { useCallback } from "react";
import { useUser } from "../../../hooks/useUser/useUser";
import { auth } from "../../../utils/auth";

export const useGoogleSignIn = () => {
  const { logout } = useUser();

  const handleGoogleLogin = useCallback(async () => {
    try {
      await logout();

      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: "select_account",
      });

      await signInWithRedirect(auth, provider, browserPopupRedirectResolver);
    } catch (error) {
      console.log(error);
    }
  }, [logout]);

  return {
    handleGoogleLogin,
  };
};
