export type FirebaseAuthUserInfo = {
  email: string | null;
  displayName: string | null;
  providerInfo: string;
  userUID: string;
  emailVerified: boolean;
  isNewUser?: boolean;
};

export enum ProviderInfo {
  GOOGLE = "google.com",
  FACEBOOK = "facebook.com",
  APPLE = "apple.com",
}

export enum SocialProvider {
  FACEBOOK = "Facebook",
  GOOGLE = "Google",
  APPLE = "Apple",
}

export interface SocialError {
  errorCode: string;
  providerName: string;
}
