import React from "react";
import { useTranslations } from "./translations";

export const SocialLoginDivider = () => {
  const tt = useTranslations();
  return (
    <div className="flex items-center my-6 w-full">
      <span className="flex-grow border-b border-light-grey"></span>
      <span className="px-3 text-grey text-xs">{tt.socialLoginDividerText}</span>
      <span className="flex-grow border-b border-light-grey"></span>
    </div>
  );
};
