import React, { useContext, useEffect, useMemo, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { AppleIcon } from "../../icons/AppleIcon";
import { FacebookIcon } from "../../icons/FacebookIcon";
import { GoogleIcon } from "../../icons/GoogleIcon";
import { NewMemberPage } from "../../pages";
import { isBrowser } from "../../utils/ssr";
import { navigateToCredentials } from "../../ecosystems/sign-up-flow/utils/navigateToCredentials";
import { Button } from "../FormElement/Button";
import { EmailInput, emailRegex } from "../FormElement/EmailInput";
import PasswordInput from "../FormElement/PasswordInput";
import { Link, useNavigate } from "../Link/Link";
import Loading from "../Loading/Loading";
import { LoginContext } from "../LoginProvider/LoginProvider";
import { pageContext } from "../PageProvider/PageProvider";
import { useAppleSignIn } from "../SocialLogin/hooks/useAppleSignIn";
import { useFacebookSignIn } from "../SocialLogin/hooks/useFacebookSignIn";
import { useGoogleSignIn } from "../SocialLogin/hooks/useGoogleSignIn";
import { useSocialLoginResult } from "../SocialLogin/hooks/useSocialLoginResult";
import { SocialButton } from "../SocialLogin/SocialButton";
import { SocialLoginDivider } from "../SocialLogin/SocialLoginDivider";
import { SocialProvider } from "../SocialLogin/types";
import { ErrorBox } from "./components/ErrorBox";
import { loginValidationTranslations, useLoginValidtionTranslations } from "./translations";
import { LoginFormValues } from "./types";
import { handleRedirection } from "./utils";
import { t } from "@lingui/macro";
import { SEO } from "../SEO/SEO";

const validationSchema = (tt: loginValidationTranslations) =>
  yup.object({
    email: yup
      .string()
      .matches(emailRegex, tt.enterValidEmail)
      .email(tt.enterValidEmail)
      .required(tt.emailIsRequired),
    password: yup.string().min(6, tt.thePasswordMustBeAtLeast6Characters).required(tt.requiredField),
  });

export const Head = ({ location }: { location: { pathname: string } }) => {
  return <SEO title="Diet Doctor - Login" pathName={location.pathname} />;
};

const LoginPage = () => {
  const loginPageTranslations = useTranslations();

  const tt = useLoginValidtionTranslations();

  const { locale } = useContext(pageContext);

  const navigate = useNavigate();

  const { handleGoogleLogin } = useGoogleSignIn();
  const { handleFacebookLogin } = useFacebookSignIn();
  const { handleAppleLogin } = useAppleSignIn();

  const { handleSocialLoginResult, firebaseSocialAuthInfo, socialLoginError } = useSocialLoginResult();

  const [isLoading, setLoading] = useState(false);
  const [readyForRedirection, setReadyForRedirection] = useState(false);

  const {
    loginWithPassword,
    loggedIn,
    user,
    loading: authLoading,
    error: loginError,
  } = useContext(LoginContext);

  const handleLogin = async (values: LoginFormValues) => {
    setLoading(true);
    try {
      await loginWithPassword(values.email, values.password);
      setReadyForRedirection(true);
    } catch (e) {
      setLoading(false);
      setReadyForRedirection(false);
    }
  };

  useEffect(() => {
    handleSocialLoginResult("login");
  }, [handleSocialLoginResult]);

  const locationSearch = useMemo(() => (isBrowser() && window?.location.search) || "", []);

  // Handle redirection to signup flow
  useEffect(() => {
    if (
      !authLoading &&
      loggedIn &&
      user &&
      firebaseSocialAuthInfo?.email &&
      firebaseSocialAuthInfo?.providerInfo !== "custom" &&
      firebaseSocialAuthInfo?.isNewUser
    ) {
      setLoading(true);
      navigateToCredentials("plan", `?content='social-login'`);
    }
  }, [authLoading, loggedIn, user, firebaseSocialAuthInfo, locale]);

  // Handle before login
  useEffect(() => {
    if (!authLoading && loggedIn && user && !readyForRedirection && !firebaseSocialAuthInfo?.isNewUser) {
      setLoading(true);
      handleRedirection(navigate, locationSearch);
    }
  }, [authLoading, loggedIn, user, readyForRedirection, navigate, locationSearch, firebaseSocialAuthInfo]);

  // Handle after login
  useEffect(() => {
    if (!authLoading && loggedIn && user && readyForRedirection) {
      handleRedirection(navigate, locationSearch);
    }
  }, [authLoading, loggedIn, user, readyForRedirection, navigate, locationSearch]);

  return isLoading ? (
    <div className="min-h-[680px]">
      <Loading />
    </div>
  ) : (
    <div className="container max-w-md px-6 mx-auto min-h-[680px]">
      <div className="py-10">
        {socialLoginError && <ErrorBox firebaseError={socialLoginError}></ErrorBox>}
        {loginError && (
          <ErrorBox>
            {loginError?.message?.includes("the email is already registered")
              ? tt.userAlreadyExistsWithEmailAndPassowrdError
              : loginPageTranslations.error || tt.loginGenericError}
          </ErrorBox>
        )}
        <h1 className="display-s">{tt.loginPageHeading}</h1>
        <p className="py-3">
          {tt.newToDD}
          <Link to={NewMemberPage} className="text-green ml-2">
            {loginPageTranslations.freeTrialCta || "Sign up for free"}
          </Link>
        </p>

        <Formik
          validationSchema={validationSchema(tt)}
          validateOnMount={true}
          initialValues={{ email: "", password: "" }}
          onSubmit={handleLogin}
        >
          {({ handleSubmit, isValid }) => (
            <Form onKeyDown={(e) => e.key === "Enter" && handleSubmit()}>
              <Field
                type="email"
                name="email"
                autoFocus={true}
                component={EmailInput}
                placeholderText={loginPageTranslations.emailLabel || "Email address"}
              />
              <Field
                name="password"
                component={PasswordInput}
                placeholderText={loginPageTranslations.passwordLabel || "Password"}
              />
              <div className="my-3">
                <a href={loginPageTranslations.forgotLink || "https://www.dietdoctor.com/login-faq"}>
                  {loginPageTranslations.forgotText || "Forgot your password?"}
                </a>
              </div>
              <Button
                type="submit"
                disabled={!isValid}
                label={loginPageTranslations.logInButton || "Log in"}
                className="w-full"
              />
            </Form>
          )}
        </Formik>

        <SocialLoginDivider />

        <div>
          <SocialButton
            provider={SocialProvider.FACEBOOK}
            icon={<FacebookIcon />}
            onClick={handleFacebookLogin}
          />

          <SocialButton
            provider={SocialProvider.GOOGLE}
            icon={<GoogleIcon />}
            onClick={handleGoogleLogin}
            className="mt-3"
          />

          <SocialButton
            provider={SocialProvider.APPLE}
            icon={<AppleIcon />}
            onClick={handleAppleLogin}
            className="mt-3"
          />
        </div>
      </div>
    </div>
  );
};

// ts-prune-ignore-next
export default LoginPage;

function useTranslations() {
  return {
    error: t({
      id: "LoginPage.error",
      message: "Incorrect email address or password",
    }),
    emailLabel: t({
      id: "LoginPage.emailLabel",
      message: "Email",
    }),
    passwordLabel: t({
      id: "LoginPage.passwordLabel",
      message: "Password",
    }),
    rememberMe: t({
      id: "LoginPage.rememberMe",
      message: "Remember me",
    }),
    logInButton: t({
      id: "LoginPage.logInButton",
      message: "Log in",
    }),
    forgotLink: t({
      id: "LoginPage.forgotLink",
      message: "/my-account/lost-password",
    }),
    forgotText: t({
      id: "LoginPage.forgotText",
      message: "Forgot your password?",
    }),
    freeTrialCta: t({
      id: "LoginPage.freeTrialCta",
      message: "Start free trial!",
    }),
  };
}
