import { navigate } from "gatsby";
import { getLocale } from "../../../utils/getLocale";
import { isBrowser } from "../../../utils/ssr";

export function navigateToCredentials(path: string, queryParams?: string) {
  if (!isBrowser()) {
    return undefined;
  }

  const locale = getLocale();

  const finalParams = queryParams || window.location.search;

  const query = finalParams ? (finalParams.indexOf("?") !== 0 ? `?${finalParams}` : finalParams) : "";

  switch (locale) {
    case "sv":
      navigate(`/se/bli-medlem/${path}${query}`);
      break;
    case "es":
      navigate(`/es/hazte-miembro/${path}${query}`);
      break;
    default:
      navigate(`/become-member/${path}${query}`);
      break;
  }
}
