import { t } from "@lingui/macro";

export function useTranslations() {
  return {
    socialButtonText: (provider: string) =>
      t({
        id: "SocialButton.provider.label",
        message: `Continue with ${provider}`,
      }),

    socialLoginDividerText: t({
      id: "SocialLogin.divider.text",
      message: "OR",
    }),
  };
}
