import { browserPopupRedirectResolver, getAdditionalUserInfo, getRedirectResult } from "firebase/auth";
import { useCallback, useState } from "react";
import { FirebaseAuthUserInfo, ProviderInfo, SocialError } from "../types";
import { auth } from "../../../utils/auth";
import { logError } from "../../../utils/logging";
import { sendEvent } from "../../../utils/gtm";

export const useSocialLoginResult = () => {
  const [firebaseSocialAuthInfo, setFirebaseSocialAuthInfo] = useState<FirebaseAuthUserInfo | undefined>(
    undefined
  );
  const [socialLoginError, setSocialLoginError] = useState<SocialError | undefined>(undefined);

  const handleSocialLoginResult = useCallback(async (flow: "signup" | "login") => {
    try {
      const result = await getRedirectResult(auth, browserPopupRedirectResolver);
      if (result) {
        const userInfo = result.user;

        if (userInfo.email !== null) {
          const additionalUserInfo = getAdditionalUserInfo(result);
          setFirebaseSocialAuthInfo({
            email: userInfo.email,
            providerInfo: result.providerId || "custom",
            displayName: userInfo.displayName,
            userUID: userInfo.uid,
            emailVerified: userInfo.emailVerified,
            isNewUser: additionalUserInfo?.isNewUser,
          });

          sendEvent({
            eventName: "social_signin",
            action: "success",
            payload: {
              provider: result.providerId,
              email: userInfo.email,
              userUID: userInfo.uid,
              flow,
            },
          });
        } else {
          setSocialLoginError({
            errorCode: "auth/email-not-exists",
            providerName: result.providerId || "",
          });
        }
      }
    } catch (error: any) {
      const providerInfo = error?.customData?._tokenResponse?.verifiedProvider[0];
      setSocialLoginError({ errorCode: error.code, providerName: getProviderName(providerInfo) });

      if (error.code === "auth/web-storage-unsupported") {
        logError("web-storage-unsupported", error);
      }

      sendEvent({
        eventName: "social_signin",
        action: "failed",
        payload: {
          email: error?.customData?.email,
          provider: providerInfo,
          errorCode: error.code,
          flow,
        },
      });

      console.log(error);
    }
  }, []);

  return {
    handleSocialLoginResult,
    socialLoginError,
    firebaseSocialAuthInfo,
  };
};

const getProviderName = (providerId: string) => {
  switch (providerId) {
    case ProviderInfo.GOOGLE:
      return "google";
    case ProviderInfo.FACEBOOK:
      return "facebook";
    case ProviderInfo.APPLE:
      return "apple";
    default:
      return "email";
  }
};
