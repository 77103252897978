import { HomePage, LoggedInHomePage } from "../../pages";
import { isBrowser } from "../../utils/ssr";
import { NavigateFn } from "../Link/Link";

export const handleRedirection = (navigate: NavigateFn, locationSearch: string) => {
  if (isBrowser()) {
    if (locationSearch.startsWith("?redirect_to=")) {
      const redirectToLink = decodeURIComponent(locationSearch.replace("?redirect_to=", ""));
      if (
        redirectToLink === `${window.origin}/` ||
        redirectToLink === `${window.origin}/se/` ||
        redirectToLink === `${window.origin}/es/` ||
        redirectToLink === `${window.origin}/home` ||
        redirectToLink === `${window.origin}/se/hem` ||
        redirectToLink === `${window.origin}/es/inicio`
      ) {
        navigate({ to: LoggedInHomePage });
      } else if (redirectToLink.includes("http:") || redirectToLink.includes("https:")) {
        window.location.replace(redirectToLink);
      } else {
        window.location.replace(window.location.protocol + "//" + redirectToLink);
      }
    } else {
      navigate({ to: LoggedInHomePage });
    }
  } else {
    navigate({ to: HomePage });
  }
};
