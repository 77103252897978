import { AuthError, browserPopupRedirectResolver, OAuthProvider, signInWithRedirect } from "firebase/auth";
import { useCallback } from "react";
import { useUser } from "../../../hooks/useUser/useUser";
import { auth } from "../../../utils/auth";

export const useAppleSignIn = () => {
  const { logout } = useUser();

  const handleAppleLogin = useCallback(async () => {
    try {
      await logout();

      const provider = new OAuthProvider("apple.com");
      provider.addScope("email");
      provider.addScope("name");

      await signInWithRedirect(auth, provider, browserPopupRedirectResolver);
    } catch (error) {
      console.log(error);
    }
  }, [logout]);

  return {
    handleAppleLogin,
  };
};
