import React from "react";
import { useTranslations } from "./translations";

type SocialButtonProps = {
  provider: string;
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: () => void;
  className?: string;
  icon?: React.ReactNode;
  dataTestid?: string;
};

export const SocialButton = ({
  type = "button",
  provider,
  icon,
  className,
  onClick,
  dataTestid,
}: SocialButtonProps) => {
  const tt = useTranslations();
  return (
    <button
      tabIndex={0}
      type={type}
      onClick={onClick}
      data-testid={dataTestid || ""}
      className={`${
        className || ""
      } w-full flex items-center rounded-lg px-3 py-3.5 text-base font-medium text-black bg-white border border-grey focus:bg-lightest-grey focus:shadow-sm hover:bg-lightest-grey transition-all duration-200`}
    >
      <span className="justify-self-start">{icon}</span>
      <span className="justify-self-center flex-grow">{tt.socialButtonText(provider)}</span>
    </button>
  );
};
