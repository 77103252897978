import * as React from "react";

export const FacebookIcon = ({ className }: React.SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    className={`${className} block`}
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m12 24c6.6274 0 12-5.3726 12-12 0-6.62742-5.3726-12-12-12-6.62742 0-12 5.37258-12 12 0 6.6274 5.37258 12 12 12z"
      fill="#1977f3"
    />
    <path
      d="m17.6711 15.4695.5316-3.4696h-3.3278v-2.25113c0-.94839.464-1.87484 1.9558-1.87484h1.5137v-2.95318s-1.3736-.23456-2.6865-.23456c-2.7406 0-4.5327 1.66052-4.5327 4.66938v2.64433h-3.04769v3.4696h3.04769v8.3853c.6109.0962 1.2369.1451 1.8748.1451s1.264-.0506 1.8749-.1451v-8.3853z"
      fill="#fff"
    />
  </svg>
);
