import { isBrowser } from "./ssr";

export const getLocale: () => "sv" | "es" | "en" | undefined = () => {
  if (isBrowser()) {
    const path = window.location.pathname;
    switch (true) {
      case path.includes("/se/"):
        return "sv";
      case path.includes("/es/"):
        return "es";
      default:
        return "en";
    }
  }
};
